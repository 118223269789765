import { graphql } from 'gatsby'
import React from 'react'

import { LargeTeaser, LargeTeaserView, Layout, Section, SEO } from '../components'
import { contentColorMap } from '../styles'
import { ThemeColorName, AllMarkdown } from '../types'

interface NewsPageProps {
  data: { allMarkdownRemark: AllMarkdown }
  location: Location
}

export const NewsPage = ({
  location: { pathname },
  data: { allMarkdownRemark }
}: NewsPageProps) => {
  const pageColor = contentColorMap.news
  const title = 'Aktuelles'
  return (
    <Layout currPath={pathname} pageColor={pageColor}>
      <SEO title={title} />
      <Section
        index={0}
        colorIndex={1}
        color={contentColorMap.news as ThemeColorName}
        headerOffset={true}
        renderBg={true}
      >
        <LargeTeaserView title={title}>
          {allMarkdownRemark.edges.map(
            (
              {
                node: {
                  fields: { slug },
                  frontmatter: { summary, image, title, date }
                }
              },
              i
            ) => (
              <LargeTeaser
                key={i}
                image={image!}
                path={slug!}
                title={title!}
                suffix={[date]}
                summary={summary!}
                index={i}
              />
            )
          )}
        </LargeTeaserView>
      </Section>
    </Layout>
  )
}
export const query = graphql`
  query News {
    allMarkdownRemark(sort: {order: DESC, fields: [frontmatter___date, frontmatter___title]}, filter: { fileAbsolutePath: { regex: "/(news)/.*\\.md$/"}, fields: { hidden: { eq: false } } }) {
      edges {
        node {
          html
          fields {
            slug
          }
          frontmatter {
            summary
            title
            date(formatString: "D.M.YYYY")
            image {
              mobile: childImageSharp {
                fluid(maxWidth: 1000, maxHeight: 700, srcSetBreakpoints: []) {
                  presentationWidth
                  ...GatsbyImageSharpFluid
                }
              }
              desktop: childImageSharp {
                fluid(maxWidth: 600, maxHeight: 420, srcSetBreakpoints: []) {
                  presentationWidth
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default NewsPage
